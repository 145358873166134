import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { linkResolver } from "../utils/linkResolver"

import { RichText } from "prismic-reactjs"
import PrismicDOM from "prismic-dom"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const blog_post_item = data.prismic.allBlog_posts.edges.slice(0, 1).pop()
  if (!blog_post_item) return null
  const post = blog_post_item.node
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={RichText.asText(post.post_title)}
        description={RichText.asText(post.post_content)}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {RichText.asText(post.post_title)}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.date_published}
          </p>
        </header>
        <section
          dangerouslySetInnerHTML={{
            __html: PrismicDOM.RichText.asHtml(post.post_content, linkResolver),
          }}
        />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByUid($uid: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismic {
      allBlog_posts(uid: $uid, lang: "en-ca") {
        edges {
          node {
            post_title
            date_published
            post_image
            post_content
            _meta {
              id
              uid
              type
            }
          }
        }
      }
    }
  }
`
